import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import TextBoxWithHeading from '../components/text/textboxWithHeading';
import Title from '../components/text/title';

import { Line } from '../components/utility/line';
import ContentContainer from '../components/global/contentContainer';

const Theme = {
  highlightColor: '#00aa90',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
  paddingBottom: 54,
};

function replaceStringWithId(string) {
  let adjustedString = string;
  return (adjustedString = adjustedString.replace(/\s+/g, '-').toLowerCase());
}

const Policies = ({ data }) => {
  const policyListings = data.prismicPolicies.data.policy_listings.map(
    (listing, index) => (
      <React.Fragment key={index}>
        <Col col={12}>
          <Line margin={'54px 36px'} color={Theme.highlightColor} />
        </Col>

        <ContentContainer
          padding={'0 36px'}
          id={replaceStringWithId(listing.policy_title.text)}
        >
          <TextBoxWithHeading
            linkColor={Theme.highlightColor}
            heading={listing.policy_title.text}
            text={listing.policy_text.html}
            padding={'0 0 0 0'}
          />
        </ContentContainer>
      </React.Fragment>
    )
  );

  return (
    <>
      <Helmet title={'Policies'} />

      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12} md={10} lg={9}>
          <ContentContainer padding={'0 36px'}>
            <Title
              margin={'108px 0 54px'}
              text={data.prismicPolicies.data.title.text}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        {policyListings}
      </Row>
    </>
  );
};

export const query = graphql`
  query PoliciesQuery {
    prismicPolicies {
      data {
        title {
          text
        }
        policy_listings {
          policy_title {
            text
          }
          policy_text {
            html
          }
        }
      }
    }
  }
`;

export default Policies;
